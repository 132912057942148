// eslint-disable-next-line import/prefer-default-export
export const mobileHero = {
  title: "Take payments wherever business takes you",
  subtext:
    "Taking payments has never been easier, thanks to our secure mobile payments app and optional Bluetooth card reader.",
  heroImg: "mobile-hero.png",
  imageClassName: "fixed-width fixed-height",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/mobile/demo",
  },
  fatTitle: "Mobile",
};

export const mobileTrusted = [
  {
    title: "300 million",
    subtext: "Marketing campaigns",
  },
  {
    title: "5+ million",
    subtext: "Loyalty redemptions",
  },
  {
    title: "2,000+",
    subtext: "New businesses each month",
  },
  {
    title: "7+ million",
    subtext: "Consumers",
  },
];

export const mobileWhiteGlove = {
  mainTitle: "Personalized service & support",
  title: "Grow your business with a team you can trust",
  content:
    "Get local service and 24/7 support from real people. We'll help optimize SpotOn Mobile to meet your needs, then show you how to use it. We’ll also set you up with a cloud-based Business Dashboard so you can monitor your business in real-time, take online payments, run marketing campaigns, and get customer insights—anywhere your business takes you.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/mobile/demo",
  },
};

export const mobileLargeFeatures = {
  sectionTitle: "Powerful tools to do business your way",
  featureBlocks: [
    {
      blockTitle: "Accept secure payments with our free app",
      blockImg: "mobile-a.png",
      blockSubTitle:
        "The SpotOn Payments mobile app makes it easy to accept payments securely right from your phone or tablet. Simply enter the customer’s card information directly onto your phone or pair your device with our Bluetooth card reader. ",
      blockList: [
        "Process card payments, voids, and refunds",
        "Save money by adding a surcharge or cash discount",
        "Available for iPhone and Android",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/mobile/demo",
      },
    },
    {
      blockTitle: "Move faster with a Bluetooth card reader",
      blockImg: "mobile-b.png",
      blockSubTitle:
        "Add an optional SpotOn Mobile card reader to create a nearly frictionless payment process for your customers that gives them the flexibility to use the payment method of their choice, including Apple Pay and Android Pay.",
      blockList: [
        "EMV and NFC enabled to accept swipes, dips, and taps",
        "Fast & easy pairing with your mobile device",
        "Quick transaction times",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/mobile/demo",
      },
    },
    {
      blockTitle: "Market, manage, invoice, and report",
      blockImg: "mobile-c.png",
      blockSubTitle:
        "SpotOn Mobile includes powerful tools to help you grow without constraint. Send marketing emails and deals, manage your online reviews, send invoices with our virtual terminal, view sales reports, and even launch a digital loyalty program to drive repeat visits.",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/mobile/demo",
      },
    },
  ],
};

export const mobileBusinessTypesCar = {
  title: "Get the flexibility you need—no matter what type of business you run",
  subtext:
    "Whether you do business on site, on the road, or simply need a backup payment method, SpotOn Mobile is right for you",
  slides: [
    {
      bg: "restaurant.png",
      cardTitle: "Restaurants",
      cardContent: "Fine Dining, Casual Dining, QSR, Food Stores, Food Trucks",
      cardIcon: "cart.svg",
      icon: "restaurant.png",
      linkTo: "/restaurants",
    },
    {
      bg: "auto.png",
      cardTitle: "Automotive",
      cardContent:
        "Oil Change Shops, Auto Parts, Auto Body, Repair, Tire Shops, Tint Shops",
      icon: "automotive.png",
      linkTo: "/automotive",
    },
    {
      bg: "beauty.png",
      cardTitle: "Health & beauty",
      cardContent:
        "Nail/Hair Salons, Tanning Salons, Barbers, Dentists, Veterinarian",
      icon: "health-and-beauty.png",
      linkTo: "/health-and-beauty",
    },
    {
      bg: "professional.png",
      cardTitle: "Professional Services",
      cardContent:
        "Lawyer, Accountant, Tow Truck, Architect, Plumber, Appraiser",
      icon: "prof-services.png",
      linkTo: "/professional-services",
    },
  ],
};

export const mobileVideo = {
  title: "Keep your guests fed and revenue rolling.",
  videoBtn: "Watch video",
  posterImg: "terminal-poster.png",
  videoUrlId: "NuZ1GIh9rjE",
};

export const mobileSolutionsSection = {
  title: "SpotOn Mobile plays well with others",
  subText: `SpotOn’s products are designed to work alongside one another so you can not only accept payments easily, but also stay connected with your customers and grow revenue.`,
  SecondaryCta: {
    ctaTitle: "View all",
    ctaTarget: "/products/mobile/demo",
  },
};

export const mobileTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const mobileIntegrations = {
  title: "Integrations",
  subtext:
    "Sync all kind of third party applications and service with SpotOn and automate your revenue growth, retention and mobile processes.",
  integrationsContent: [
    {
      title: "Compatible with SpotOn Register.",
      subtext:
        "Run and grow your business more efficiently than ever before with our cutting-edge point-of-sale solution.",
      contentImg: "register.png",
      ImgClassName: "register",
      btnInfo: {
        ctaTitle: "How it Works",
        ctaTarget: "/products/mobile/demo",
      },
    },
    {
      title: "Out of the box Stripe integration.",
      subtext:
        "Accept mobiles and move money globally with Stripe’s powerful APIs and software solutions",
      contentImg: "phone.png",
      ImgClassName: "phone",
      btnInfo: {
        ctaTitle: "Read More",
        ctaTarget: "/products/mobile/demo",
      },
    },
  ],
};

export const mobileCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/mobile/demo",
  },
};

export const mobileFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/faq",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const mobileSolutionsSlides = [
  {
    title: "Register",
    link: "/products/register",
    img: "Register.png",
  },
  {
    title: "Loyalty",
    link: "/products/loyalty",
    img: "Loyalty.png",
  },
  {
    title: "Marketing",
    link: "/products/marketing",
    img: "Marketing.png",
  },
  {
    title: "Website",
    link: "/products/website",
    img: "E-commerce & websites.png",
  },
  {
    title: "Terminal",
    link: "/products/terminal",
    img: "Terminal.png",
  },
];
