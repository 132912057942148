import React, { useEffect } from "react";
import loadable from "@loadable/component";
import { navigate } from "gatsby";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  mobileHero,
  mobileTrusted,
  mobileWhiteGlove,
  mobileLargeFeatures,
  mobileBusinessTypesCar,
  // mobileVideo,
  mobileSolutionsSection,
  mobileSolutionsSlides,
  // mobileTestimonials,
  mobileCTA,
  // mobileFaqs,
} from "../../../data/product-pages/mobile-data";
import Hero from "../../../components/Hero/hero";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import BusinessTypes from "../../../components/BusinessTypes/BusinessTypes";
// import PartnerLogos from "../../../components/PartnerLogos/PartnerLogos";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import ProductLines from "../../../components/ProductLines/ProductLines";
// import SuccessStories from "../../../components/SuccessStories/SuccessStories";
import { autoBTSlides, heroSuccess } from "../../../data/success-stories-data";
import ogImage from "../../../images/global_assets/og-image.png";

const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);
const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnerLogos")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const ProductLines = loadable(() =>
  import("../../../components/ProductLines/ProductLines")
);
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);

const whiteGloveImport = "terminal-pp-white-glove.png";

const Mobile = () => {
  useEffect(() => {
    navigate("/products/virtual-terminal/");
  }, []);
  return (
    <Layout>
      <SEO
        title="Mobile POS System | Mobile Credit Card Processing | SpotOn"
        description="Take payments on the go or wherever you go with SpotOn's mobile POS solutions. Monitor your business in real-time, take online payments, & more. Get a demo!"
        image={`https://spoton.com/${ogImage}`}
        cannonical="https://www.spoton.com/products/mobile/"
      />
      <Hero sectionData={mobileHero} circleBg={false} />
      <TrustedNumbers numbersArray={mobileTrusted} />
      <WhiteGlove
        sectionData={mobileWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={mobileLargeFeatures}
        style={{ marginTop: "128px" }}
      />
      <SuccessStories sectionData={heroSuccess} slides={autoBTSlides} />
      <BusinessTypes sectionData={mobileBusinessTypesCar} />
      <PartnerLogos />
      <ProductLines
        sectionData={mobileSolutionsSection}
        productSlides={mobileSolutionsSlides}
      />
      <LargeCta sectionData={mobileCTA} />
      {/* <Faqs sectionData={mobileFaqs} /> */}
    </Layout>
  );
};

export default Mobile;
